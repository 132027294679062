import { combineReducers } from 'redux'
import windowActions from './windowActions'
import startMenuActions from './startMenuActions'
import osActions from './osActions'

export default combineReducers({
  windowActions,
  startMenuActions,
  osActions
});

/*
* Reducers specify how the application's state changes in response to actions sent to the store. Remember that
* actions only describe what happened, but don't describe how the application's state changes.
*/
