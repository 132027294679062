import {
  LAUNCH_VIRUS,
  START_VIRUS,
  ADD_LINES_TO_VIRUS,
  DISPLAY_BSOD,
  RESET_VIRUS
} from '../actions'

const initialState = {
  isVirusLaunched: false,
  isVirusStarted: false,
  isBsodDisplayed: false,
  virusLines: []
}

export default function execute(state = initialState, action) {
  switch (action.type) {
    case LAUNCH_VIRUS:
      return Object.assign({}, state, {
        isVirusLaunched: true
      })
    case START_VIRUS:
      return Object.assign({}, state, {
        isVirusStarted: true
      })
    case ADD_LINES_TO_VIRUS:
      return Object.assign({}, state, {
        virusLines: state.virusLines.concat(action.lines)
      })
    case DISPLAY_BSOD:
      return Object.assign({}, state, {
        isBsodDisplayed: true
      })
    case RESET_VIRUS:
      return Object.assign({}, initialState)
    default:
      return state
  }
}