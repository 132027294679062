import React from 'react';
import './App.css';
import { connect } from 'react-redux';

class DesktopIcon extends React.Component {

  constructor(props){
    super(props)
    this.do = this.do.bind(this)
  }

  do(){
    if(this.props.func){
      this.props.dispatch(this.props.func(this.props.target));
    }
  }

  render() {
    return (
      <div onClick={this.do}>
        <img src={this.props.icon} alt=""/>
        <span className="caption">{this.props.title}</span>
      </div>
    );
  }
}

export default connect()(DesktopIcon)
