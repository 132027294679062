import React from 'react';
import './App.css';
import {
  closeWindow,
  focusWindow,
  closeStartMenu,
  maximizeWindow
} from './actions'
import {
  connect
} from 'react-redux';

import close_icon from './icons/close-icon.png';

const Window = (props) => {

  const handleClick = (action) => {
    if (action.action) {
      action.action(props.name)
    }
  }

  const handleFocus = () => {
    props.closeStartMenu();
    if (props.focusedWindow === props.name) {
      props.maximizeWindow(props.name);
    } else {
      props.focusWindow(props.name);
    }
  }

  return (
    <div className={`window border-only ${props.windowClass} ${props.openedWindows.includes(
              props.name) || props.show ? ""
              : " hidden"}${props.maximizedWindows.includes(props.name)
              ? " maximized" : ""}`}>
        <div className={`header${props.focusedWindow === props.name ? " active"
                : ""} vertical-center-items`}>
          <div className="window-title fill vertical-center-items"
          onClick={(e) => {
            handleFocus();
            e.stopPropagation()
          }}>
            {props.icon && (
                <img src={props.icon} alt="" className="header-icon"/>
            )}
            {props.title}
          </div>
          {
            props.actions && props.actions.map(action => (
                    <div onClick={(e) => {
                      handleClick(action);
                      e.stopPropagation()
                    }} className="icon interactable center-items"
                         key={Math.random()}>
                      <img src={action.icon} alt=""/>
                    </div>
                )
            )
          }
          <div className="icon interactable center-items" onClick={(e) => {
            if(props.name!=='bsodconsole'){
              props.closeWindow(props.name);
            }
            e.stopPropagation()
          }}>
            <img src={close_icon} alt=""/>
          </div>
        </div> {
      props.children
    }
    </div>
  );
}

const mapDispatchToProps = {
  closeWindow,
  focusWindow,
  closeStartMenu,
  maximizeWindow
}

const mapStateToProps = state => {
  return {
    focusedWindow: state.windowActions.focusedWindow,
    openedWindows: state.windowActions.openedWindows,
    maximizedWindows: state.windowActions.maximizedWindows,
    minimizedWindows: state.windowActions.minimizedWindows
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Window)