import {
  CLOSE_START_MENU,
  TOGGLE_START_MENU,
  SHUT_DOWN
} from '../actions'

const initialState = {
  isOpen: false,
  isShutDown: false
}

export default function execute(state = initialState, action) {
  switch (action.type) {
    case CLOSE_START_MENU:
      return Object.assign({}, state, {
        isOpen: false
      })
    case TOGGLE_START_MENU:
      return Object.assign({}, state, {
        isOpen: !state.isOpen
      })
    case SHUT_DOWN:
      return Object.assign({}, state, {
        isShutDown: true,
        isOpen: false
      })
    default:
      return state
  }
}