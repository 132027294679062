import React from 'react';
import './App.css';
import WINDOWS from './windows'

import {
  toggleStartMenu,
  taskbarSelect,
  closeStartMenu
} from './actions'
import {
  connect
} from 'react-redux';

import windows from './icons/windows-1.png';
import printer_question from './icons/printer_question-2.png';
import sndvol32_output from './icons/sndvol32_output-1.png';
import ac_plug from './icons/ac_plug-1.png';
import network_cool_two_pcs from './icons/network_cool_two_pcs-4.png';
import cd_drive_purple from './icons/cd_drive_purple-4.png';
import loudspeaker_rays from './icons/loudspeaker_rays-1.png';
import msn_messenger from './icons/msn_messenger.png';

class Taskbar extends React.Component {

  constructor(props) {
    super(props)
    this.getTaskbarItems = this.getTaskbarItems.bind(this)
  }

  fromCamelCase(string) {
    var result = string.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  getTaskbarItems() {
    const windows = this.props.openedWindows.map((openedWindow) => ({
      'name': WINDOWS[openedWindow].name,
      'id': openedWindow,
      'icon': WINDOWS[openedWindow].icon
    }));

    return windows.map((window) =>
      <div key={window.id}
             className={`taskbar-item interactable ${this.props.focusedWindow
             === window.id ? "interactable-active" : ""}`}
             onClick={() => {
               this.props.taskbarSelect(window.id);
               this.props.closeStartMenu();
             }}>
          {window.icon && (
              <img src={window.icon} alt="" className="header-icon"/>
          )}{window.name}
        </div>
    )
  }

  render() {
    return (
      <div className={`taskbar-wrapper ${this.props.isBsodDisplayed ? "hidden":""}`}>
          <div className="taskbar-menu-holder interactable"
               onClick={this.props.toggleStartMenu}>
            <div className="taskbar-menu center-items fill">
              <img src={windows} alt="" height="16" width="16"
                   style={{marginRight: '3px'}}/>
              Start
            </div>
          </div>
          <div id="taskbar-items" className="fill">
            {this.getTaskbarItems()}
          </div>
          <div className="taskbar-icons non-interactable center-items">
            <img src={printer_question} alt=""/>
            <img src={sndvol32_output} alt=""/>
            <img src={ac_plug} alt=""/>
            <img src={network_cool_two_pcs} alt=""/>
            <img src={cd_drive_purple} alt=""/>
            <img src={loudspeaker_rays} alt=""/>
            <img src={msn_messenger} alt="" width="16px" height="16px"/>
            {
              new Intl.DateTimeFormat(
                  'en-GB',
                  {
                    hour: 'numeric',
                    minute: 'numeric'
                  }
              ).format(new Date())
            }
          </div>
        </div>
    )
  }
}

const mapDispatchToProps = {
  toggleStartMenu,
  taskbarSelect,
  closeStartMenu
}

function mapStateToProps(state) {
  return {
    openedWindows: state.windowActions.openedWindows,
    focusedWindow: state.windowActions.focusedWindow,
    isBsodDisplayed: state.osActions.isBsodDisplayed
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Taskbar)