import React from 'react';
import './App.css';
import Notepad from './Notepad';

const Readme = () => {

  return (
    <Notepad name="readMe" title={"README"}>
        <div>
          <p>&emsp;&emsp;&emsp;Welcome to Windows 98!</p>
          <p>-------------------------------------</p>
          <p>Hi, I'm Joe.</p>
          <p>I made this Windows 98 emulator with React and Redux so that you can escape to a simpler time.</p>
          <p>The taskbar really maintains the state of what windows you've opened! Don't you think that's cool? No? You got some attitude, mister.</p>
          <p>I hope this reminds you of the good old days of the internet, before everyone else came along and ruined it.</p>
          <p>P.S.</p>
          <p>Whatever you do, don't open the funny picture that my online friend sent me on AIM. It has a computer bug in it or something.</p><br/>
        </div>
      </Notepad>
  );
}

export default Readme