import React from 'react';
import './App.css';

class Dropdown extends React.Component {

  render() {
    return (
      <div className="dropdown non-interactable">
        <div className="dropdown-internal fill">
        {
          this.props.elements && this.props.elements.map(function(element,i){
            return (
              <option key={element+i}>{element}</option>
            )}
          )
        }
        </div>
        <div className="scroll-button border-only">
          <div className="chevron-down">
          </div>
        </div>
      </div>
    );
  }
}

export default Dropdown;
