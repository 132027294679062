import React from 'react';
import './App.css';

class Button extends React.Component {

  render() {
    return (
      <div className="interactable center-items" onClick={this.props.action}>
        {this.props.text}
      </div>
    );
  }
}

export default Button;
