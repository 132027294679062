import React from 'react';
import './App.css';

class WindowTabs extends React.Component {

  render() {
    return (
      <div className="window-tabs">
        <div className="window-tabs-active">General</div>
        <div>Device Manager</div>
        <div>Hardware Profiles</div>
        <div>Performance</div>
      </div>
    );
  }
}

export default WindowTabs;
