import React from 'react';
import './App.css';
import Window from './Window';
import WindowTabs from './WindowTabs';
import WindowActionButtons from './WindowActionButtons';
import {
  closeWindow
} from './actions'
import {
  connect
} from 'react-redux';

import question_icon from './icons/question-icon.png';

class SystemProperties extends React.Component {
  render() {
    return (
      <Window name="systemProperties" title="System Properties" windowClass="system-properties-window" actions={[{'action':null,'icon':question_icon}]}>
        <WindowTabs />
        <div className="window-content border-only fill">
          <div className="system-properties-image">
            <img src={require("./images/system_properties.png")} alt="" />
          </div>
          <div className="system-properties">
            System:
            <ul>
              <li>Microsoft Windows 98</li>
              <li>Pure HTML/CSS Edition</li>
            </ul>
            Created by:
            <ul>
              <li>Joe Rowe</li>
              <li>22 Years Later</li>
              <li>696D20766567616E</li>
            </ul>
            Computer:
            <ul>
              <li>GenerallyIntel</li>
              <li>Pentium(r) Processor II</li>
              <li>2.0MB RAM</li>
            </ul>
          </div>
        </div>
        <WindowActionButtons actions={
            [
            {text:'OK',action:this.props.closeWindow, params:'systemProperties'},
            {text:'Cancel',action:this.props.closeWindow, params:'systemProperties'}]
          }/>
      </Window>
    );
  }
}

const mapDispatchToProps = {
  closeWindow
}

export default connect(null, mapDispatchToProps)(SystemProperties)