import React from 'react';
import './App.css';

class WindowToolbar extends React.Component {

  render() {
    return (
      <div className="window-menu-items">
        <div>File</div>
        <div>Edit</div>
        <div>Search</div>
        <div>Help</div>
      </div>
    );
  }
}

export default WindowToolbar;
