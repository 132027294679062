import React from 'react';
import './App.css';
import { shutDown } from './actions'
import { connect } from 'react-redux';

class StartMenu extends React.Component {

  stopPropagation(e){
    e.stopPropagation();
  }

  render() {
    return (
      <div className={`menu border-only ${this.props.isOpen ? "" : "hidden"}`} onClick={this.stopPropagation}>
        <div className="menu-sidebar">
          <div className="menu-sidebar-rest fill">
          </div>
          <div className="menu-sidebar-text center-items">
            <span style={{fontWeight: 'bold'}}>Windows</span>98
          </div>
        </div>
        <div className="menu-items">
          <div className="menu-item">
            <img src={require("./icons/programs.png")} alt="" width="32" height="32" style={{padding:'8px'}} />
            Programs
            <div className="chevron">
            </div>
          </div>
          <div className="menu-item">
            <img src={require("./icons/directory_favorites_small-5.png")} alt="" />
            Favorites
            <div className="chevron">
            </div>
          </div>
          <div className="menu-item">
            <img src={require("./icons/directory_open_file_mydocs_small-4.png")} alt="" />
            Documents
            <div className="chevron">
            </div>
          </div>
          <div className="menu-item">
            <img src={require("./icons/settings_gear-3.png")} alt="" width="48" height="48" />
            Settings
            <div className="chevron">
            </div>
          </div>
          <div className="menu-item">
            <img src={require("./icons/search_file_2_cool-5.png")} alt="" />
            Find
            <div className="chevron">
            </div>
          </div>
          <div className="menu-item">
            <img src={require("./icons/help_book_cool_small-5.png")} alt="" />
            Help
          </div>
          <div className="menu-item">
            <img src={require("./icons/application_hourglass_small-4.png")} alt="" />
            Run
          </div>
          <div className="divider">
          </div>
          <div className="menu-item" onClick={this.props.shutDown}>
            <img src={require("./icons/shut_down_cool-5.png")} alt="" />
            Shut Down
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  shutDown
}

function mapStateToProps(state) {
  return {
    isOpen: state.startMenuActions.isOpen
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StartMenu)
