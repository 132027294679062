import React from 'react';
import {
  useDrag
} from 'react-dnd';
import './App.css';
import {
  connect
} from "react-redux";
import {
  focusWindow,
  closeStartMenu
} from "./actions";

const style = {
  position: 'absolute'
};

//TODO this should be responsible for resizing too.

const WindowHolder = ({
  id,
  left,
  top,
  children,
  focusWindow,
  closeStartMenu,
  focusedWindow,
  minimizedWindows,
  maximizedWindows,
  openedWindows,
  orderedWindows
}) => {

  const [{
    isDragging
  }, dragRef] = useDrag(
    () => {
      closeStartMenu();

      if (openedWindows.includes(id)) {
        focusWindow(id);
      }
      return {
        type: "window",
        item: {
          id,
          left,
          top
        },
        collect: (monitor) => ({
          isDragging: monitor.isDragging()
        })
      }
    }, [id, left, top]);

  const z_index = {
    zIndex: id === 'bsod' ? 100 : 10 + orderedWindows.indexOf(id)
  }

  if (isDragging) {
    return <div ref={dragRef} />;
  } else {
    return (
      <div
        ref={dragRef}
        style={{...style, ...z_index, left, top }}
        className={`
        ${focusedWindow === id ? 'front' : ''}
        ${maximizedWindows.includes(id) ? ' maximized' : ''}
        ${minimizedWindows.includes(id) ? ' hidden' : ''}
        ${openedWindows.includes(id) ? '' : ' hidden '}`}
        onClick={() => focusWindow(id)}>
          { children }
        </div>
    );
  }
}

const mapDispatchToProps = {
  focusWindow,
  closeStartMenu
}

const mapStateToProps = state => {
  return {
    focusedWindow: state.windowActions.focusedWindow,
    openedWindows: state.windowActions.openedWindows,
    orderedWindows: state.windowActions.orderedWindows,
    maximizedWindows: state.windowActions.maximizedWindows,
    minimizedWindows: state.windowActions.minimizedWindows
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WindowHolder)