import React from 'react';
import {
  useState,
  useEffect
} from 'react';
import './App.css';
import Window from './Window';

import {
  maximizeWindow,
  minimizeWindow,
} from './actions'
import {
  connect
} from 'react-redux';


import Dropdown from './Dropdown';
import IconButton from './IconButton';

import minimize_icon from './icons/minimize_icon.png';
import maximize_icon from './icons/maximize_icon.png';

import dos_icon from './icons/ms_dos-1.png';

import dashed_square from './icons/dashed_square.png'
import greyed_out_files from './icons/greyed_out_files.png';
import clipboard from './icons/clipboard.png';
import expand from './icons/expand.png';
import pointing_to_file_hand from './icons/pointing_to_file_hand.png';
import two_windows from './icons/two_windows.png';
import capital_A from './icons/capital_A.png';

import MarkovChain from './projects/YoMomma/MarkovChain'

const YoMommaConsole = ({
  minimizeWindow,
  maximizeWindow
}) => {

  const baseJokes = [
    "yo momma so fat when she farts she can melt the ice on pluto",
    "yo momma so stupid she took a ruler to bed to measure how long she slept",
    "yo momma so fat that she's the big rolling ball on indiana jones",
    "yo momma so dumb she asked the operator for the number for 911",
    "yo momma so poor that she has to chase down the garbage truck with a shopping list",
    "yo momma so poor that i found in the dumpster and asked her what she was doing she said christmas shopping",
    "yo momma so poor that she tried to use food stamps on a gumball machine",
    "yo momma so poor she waves around a popsicle stick and calls it air-conditioning",
    "yo momma so old she knew burger king when he was still a prince",
    "yo momma so old that her birth certificate is in roman numerals",
    "yo momma so ugly your father takes her to work with him so he doesn't have to kiss her goodbye",
    "yo momma so ugly she tried to take a bath and the water jumped out",
    "yo momma so ugly she made an onion cry",
    "yo momma so ugly when she goes bungie jumping instead of putting the chord around her ankles they put it around her neck",
    "yo momma so fat she sat on the beach and greenpeace threw her in",
    "yo momma so fat she's got more chins than a chinese phone book",
    "yo momma so fat when she lies on the beach no one else gets sun",
    "yo momma so fat that when she bungie jumps she goes straight to hell",
    "yo momma so fat she fell in love and broke it",
    "yo momma so fat when she goes to a resturant she looks at the menu and says okay",
    "yo momma so fat that when she wants people to shake her hand she has to give directions",
    "yo momma so fat when she was floating in the ocean spain claimed her for their new world",
    "yo momma so fat when you get on top of her your ears pop",
    "yo momma so dumb she sold her car for gasoline money",
    "yo momma so dumb it took her two hours to watch 60 minutes",
    "yo momma so dumb that when she put a quater in the parking meter she waited half-an-hour for the gum to come out",
    "yo momma so dumb she tried to jump out the basement window",
    "yo momma so dumb she tripped over a chored-less phone",
    "yo momma so fat she broke a branch on the family tree",
    "yo momma so ugly she stuck her head out the window and got arrested for mooning",
    "yo momma so dumb she studied for the drug test",
    "yo momma so fat that when she walked by my tvi missed 2 episodes",
    "yo momma so dumb she burnt down the house using a cd burner",
    "yo momma so ugly she took one look at the sun and it hide behind a cloud",
    "yo momma so stupid she tried to put m&ms in alphabetical",
    "yo momma so stupid it took her 2 hours to watch 60 minutes",
    "yo momma so stupid she missed the 44 bus so she just took the 22 bus twice",
    "yo momma so stupid she heard it was chilly outside so she went and got a spoon",
    "yo momma so fat she tripped over 4th ave and landed on 12th ave",
    "yo momma so fat she sits down at the resturant opens the menu and says okay",
    "yo momma so fat when you get on top of her your ears pop",
    "yo momma so fat people jog around her instead of the block for a longer run",
    "yo momma so fat i had to take two trains and a bus just to get on her good side",
    "yo momma so fat the last time she saw 90210 it was on the bathroom scale",
    "yo momma so fat she got run over and said who threw that rock",
    "yo momma so fat every time she turns around its her birthday",
    "yo momma so fat when she put on high heels she struck oil",
    "yo momma so fat that when she fell of the bed she fell of both sides",
    "yo momma so fat with her we are 2 nations",
    "yo momma hair so short instead of using rollers to curl her hair she uses rice",
    "yo momma so fat she already been to the future",
    "yo momma so fat she has to get out of the car to change gear",
    "yo momma so fat i drove around her and ran out of gas",
    "yo momma so fat she went to see a hockey game and she thought the puck was a burned hambuger",
    "yo momma so fat she makes a blue whale look like a tic tac",
    "yo momma so fat that she smokes turkey",
    "yo momma so poor when i asked her what for dinner she put her foot on the table and said corn",
    "yo momma so hairy bigfoot took pictures of her",
    "yo momma so fat she has every cateror on speed dial",
    "yo momma so fat that she has to drink diet water",
    "yo momma so ugly that she stuck her head out the windowand got arrested for disturbing the peace",
    "yo momma so dumb that she shoved the phone up her ass and thought that she was making a booty call",
    "yo momma so dumb when someone told her to speak her mind she was speechless",
    "yo momma so dumb that she put lipstick on her forehead so she could make up her mind",
    "yo momma so hairy when she shaves she uses a lawnmower",
    "yo momma so old she saw jurrasic park and said that brings back memories",
    "yo momma so fat she jumped up in the sky and got stuck",
    "yo momma so fat she has to borrow a belt from orion",
    "yo momma so poor that when someone rings the doorbell she has to stick her head through a crack and say dingdong",
    "yo momma so fat you can hit her stomach and ride the waves",
    "yo momma so poor when somebody asked her where the bathroom was in her house she said pick a corner",
    "yo momma so dumb she tried to drown a fish",
    "yo momma so dumb she stole free bread",
    "yo momma so dumb she got locked in a grocery store and starved to death",
    "yo momma so dumb she took a spoon to the super bowl",
    "yo momma so old moses is in her yearbook",
    "yo momma so fat her yearbook picture was taken by satellite",
    "yo momma so dumb when the baseball coach told her to run home with the ball she literally ran home with the ball",
    "yo momma so ugly i threw a rock at her and the rock stopped came back and said to me i ain't goin near that creepy thing",
    "yo momma so stupid she tried to jump out the basement window",
    "yo momma so fat she got hit by a parked car",
    "yo momma so fat when she stands on the curb in her yellow raincoat people yell out taxi",
    "yo momma so hairy it looks like she has buckwheat in a headlock",
    "yo momma so fat every time she turns around its her birthday",
    "yo momma so fat she had to use a pillowcase for a sock",
    "yo momma so dumb it took her an hour to make minute rice",
    "yo momma so poor that when i saw her kick a can down the street i asked her what she was doing and she said moving",
    "yo momma so fat she's got as many chins as a chinese phonebook",
    "yo momma so fat that she filled up the tub and then turned the water on",
    "yo momma so poor she looked at a cardboard house and said look my mansion",
    "yo momma so poor she found a penny on the ground and said i won the lottery (even though she still had to take out tax)",
    "yo momma so bald i can see what she thinkin",
    "yo momma so old that i told her to act her age and she died",
    "yo momma so old that when she was in school they didn't have history",
    "yo momma so nasty she brought her own crabs to the beach",
    "yo momma so ugly we tied a steak to her neck so she could play with the family dog",
    "yo momma so stupid she thought a quarterback was a refund",
    "yo momma so fat she played billiards with the planets",
    "yo momma so fat she sat on a rainbow and made skittles",
    "yo momma so fat she sat on a dollar and made change",
    "yo momma so ugly that satan sent her back",
    "yo momma so fat that your dad has to have a heavy machinary license to have sex",
    "yo momma so poor that i walked in the front door and fell out the back",
    "yo momma so poor that i borrowed your skateboard and she called the cops saying her car got stolen",
    "yo momma so dumb she thought a harddrive was a rough road",
    "yo momma so dumb she thought floppy disk was when her back hurt",
    "yo momma so fat that when she sits around the house she sits around the house",
    "yo momma so fat she bathes in the ocean",
    "yo momma so fat she isn't allowed on the 2nd floor",
    "yo momma so fat that she needed a blinkin light so planes wouldn't hit her",
    "yo momma so fat she seen a bus and thought it was a twinkie",
    "yo momma so ugly she scared michael jackson",
    "yo momma so poor she married michael jackson",
    "yo momma so old that she birthed god",
    "yo momma so fat she has to sit in the middle of the car",
    "yo momma so fat that when she sat on a nintendo gamecube she changed it into a gameboy advance sp",
    "yo momma so fat she got more chins than in the chinese phone book",
    "yo momma so stupid she walked into an antique store and said whats new",
    "yo momma so ugly they moved halloween to her birthday",
    "yo momma so fat when a meteorite hit her she said whose throwing pebbles",
    "yo momma so dumb she threw a rock at the ground and missed",
    "yo momma so stupid she tried to drown a fish",
    "yo momma so stupid she clibed over a glass wall to see the other side",
    "yo momma so old george washington was her first boyfriend",
    "yo momma so stupid she thought michael jackson was a boy",
    "yo momma so stupid she tripped over a cordless phone",
    "yo momma so stupid she sold her car for gas money",
    "yo momma went to a hotel and asked for a water bed and they put a blanket over the ocean",
    "yo momma so fat she jumped in the ocean and a whale started to sing we are family",
    "yo momma so fat she uses two grey hound buses for roller blades",
    "yo momma so stupid she tried wakin up a sleepin bag",
    "yo momma so stupid she went to dr dre for a papsmear",
    "yo momma so old her bible is signed by jesus",
    "yo momma so fat when she stepped on the scale it said - ouch",
    "yo momma so fat when she stepped on the scale it said - to be continued",
    "yo momma so fat when she stepped on the scale it said better luck next time",
    "yo momma so fat that when she stands on the weighing scales it comes up with her mobile phone number",
    "yo momma so fat when she walks down the street in a yellow rain suit everyone starts yelling taxi",
    "yo momma so fat when she jumps in the ocean with a blue swimsuit on the whales start singing we are family",
    "yo momma so fat when she jump in the air she got stuck",
    "yo momma so fat when she trip and fall she made the grand canyon",
    "yo momma so fat when she step on the scale said out of order",
    "yo momma so fat when she wore a red rain jacket everyone yelled hey kool-ade",
    "yo momma so fat when she bungee jump she broke the bridge in half",
    "yo momma so fat she wears a vcr as a pager",
    "yo momma so fat that the city gave her own zip code",
    "yo momma so fat everyone at the baseball sadtium sat on her",
    "yo momma so fat when she drop you off at school she got a ticket for littering",
    "yo momma so fat takes you a five mile walk around her",
    "yo momma so fat she jumped in the air and got stuck",
    "yo momma so fat she needs a boomerang to get her belt on",
    "yo momma so stupid she was flicking pennies in a wishing well and someone asked what are you doing so she said im paying the water bill",
    "yo momma so stupid she made up her own iq test and failed it three times",
    "yo momma so fat she entered an ugly contest and they said no professionals aloud",
    "yo momma so dumb she so dumb that on her way to disney world she saw a sign that said disney world left so she turned around and went back home",
    "yo momma so fat she makes godzilla look like an action figure",
    "yo momma breath stink so bad people look forward to her farts",
    "yo momma so fat when the cops drove by and saw her on the corner they yelled hey you guys break it up",
    "yo momma so fat her belt size is equator",
    "yo momma so fat when her beeper goes off people think she's backing up",
    "yo momma so ugly yo daddy takes her to work so he dont have to kiss her goodbye",
    "yo momma so fat she jumped out of a tree and went straight to you know",
    "yo momma so fat she wore heels once and struck oil",
    "yo momma so fat if she gained 5 more pounds she could get group insurance",
    "yo momma so po i asked were the bathroom was and she said 3rd bucket on the right",
    "yo momma so stupid she asked what kind of jeans i was wearing i said guess she said levis",
    "yo momma so dumb she brung a spoon to the super bowl",
    "yo momma so dumb she sits on top of the tv and watches the couch",
    "yo momma so fat jesus cant lift her soul",
    "yo momma so fat she fell in love and broke it your house so small i put the key in the keyhole and i killed three people",
    "yo momma so poor i saw her walking down the street kicking a can i asked her what she was doing and she said moving",
    "yo momma so stupid she got hit by a parked car",
    "yo momma so fat that she is on both sides of the family",
    "yo momma so old that she still has jesus pager number",
    "yo momma so fat that they said it was chili outside then she brought out a bowl",
    "yo momma so fat that she has to use a vcr as a pager",
    "yo momma so fat she makes shamu look like a tic-tic",
    "yo momma so fat she went to jenny craig and the only thing she lost was $1999",
    "yo momma so stupid when she read on her job application not to write on botted line below she put ok",
    "yo momma so fat when her beeber goes off people thought she backing up",
    "yo momma so poor that when i walked in your house and steped on a lighted cigarette she said who turned off the heater",
    "yo momma so big she has her own zip code",
    "yo momma stepped on a scale and it said one at a time please",
    "yo momma so fat that when god said let there be light he asked her to move over",
    "yo momma so fat that when she swims in the sea the whales start singing we are family",
    "yo momma pits are so hairy she looks like she's got don king in a headlock",
    "yo momma so fat that when she went dooky the whole world flooded & everyone had to live on a piece of poop",
    "yo momma so poor that when i came to the door she stuck her head out of the window & said ding-dong",
    "yo momma so ugly when my dad mooned her she looked into it & thot it was her reflection & replied how did my hair get so curly",
    "yo momma so fat she had to iron her pants on the driveway",
    "yo momma breath so bad when she went to the dentist to get a filling the dentist had to give himself gas",
    "yo momma so fat that when she walks in high heels she strikes oil",
    "yo momma so dumb that she takes an hour to make minute rice",
    "yo momma so dumb she went to go fill out a job application it said country __________ she wrote free",
    "yo momma so stupid when asked sex on an application she put m f and sometimes wednesdays too"
  ];
  const markovChain = new MarkovChain(baseJokes);
  const [jokes, setJokes] = useState([markovChain.build()]);

  useEffect(() => {
    var element = document.getElementById("console-content");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  });


  return (
    <Window
        name="yoMommaConsole"
        title="MS-DOS Prompt"
        icon={dos_icon}
        windowClass="console-window"
        actions={[
          {'action': minimizeWindow, 'icon': minimize_icon},
          {'action': maximizeWindow, 'icon': maximize_icon}]}>
        <div className="window-menu-items">
          <Dropdown elements={['Auto', 'Something', 'Else']}/>
          <div className="icon-holder">
            <IconButton icon={dashed_square}/>
            <IconButton icon={greyed_out_files}/>
            <IconButton icon={clipboard}/>
          </div>
          <IconButton icon={expand}/>
          <div className="icon-holder">
            <IconButton icon={pointing_to_file_hand}/>
            <IconButton icon={two_windows}/>
          </div>
          <IconButton icon={capital_A}/>
        </div>
        <div id="console-content"
             className="console-content margin non-interactable fill"
             onClick={() => setJokes([...jokes,markovChain.build()])}>
          Microsoft(R) Windows 98 < br/>
          &emsp;&emsp;(C) Copyright Microsoft Corp 1981 - 1998.<br/><br/>
          <span style={{color:"yellow"}}>(Click this window for another!)</span><br/><br/>
          {jokes.map((joke, id) => (<div key={`JOKE${id}`}>{joke}<br/><br/></div>))}
        </div>
      </Window>
  );
}

const mapDispatchToProps = {
  maximizeWindow,
  minimizeWindow
}

export default connect(null, mapDispatchToProps)(YoMommaConsole)