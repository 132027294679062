import {
  OPEN_WINDOW,
  OPEN_WINDOWS,
  CLOSE_WINDOW,
  MAXIMIZE_WINDOW,
  MINIMIZE_WINDOW,
  TASKBAR_SELECT,
  FOCUS_WINDOW,
  RESET_ALL_WINDOWS
} from '../actions'

const initialState = {
  openedWindows: ['readMe'],
  orderedWindows: ['readMe'],
  minimizedWindows: [],
  maximizedWindows: ['readMe'],
  focusedWindow: 'readMe'
}

function removeFromArray(arr, item) {
  return removeItemsFromArray(arr, [item])
}

function removeItemsFromArray(arr, items) {
  return arr.filter(element => !items.includes(element))
}

function reorderWindows(arr, windowId) {
  let allWindows = [...arr].filter(item => item !== windowId)
  allWindows.push(windowId);
  return allWindows;
}

export default function execute(state = initialState, action) {
  let otherOpenWindows = removeFromArray(state.openedWindows, action.name)
  switch (action.type) {
    case OPEN_WINDOW:
      return Object.assign({}, state, {
        openedWindows: [...new Set([...state.openedWindows, action.name])],
        orderedWindows: reorderWindows(state.orderedWindows, action.name),
        focusedWindow: action.name,
        maximizedWindows: action.name === 'systemProperties' ? state.maximizedWindows : [...new Set([...state.maximizedWindows, action.name])],
        minimizedWindows: removeFromArray(state.minimizedWindows, action.name)
      })
    case OPEN_WINDOWS:
      const newOrderedWindows = [...new Set([...state.orderedWindows, ...action.names])];
      return Object.assign({}, state, {
        openedWindows: [...new Set([...state.openedWindows, ...action.names])],
        orderedWindows: newOrderedWindows,
        focusedWindow: newOrderedWindows[newOrderedWindows.length - 1],
        maximizedWindows: [...new Set([...state.maximizedWindows, ...action.names])],
        minimizedWindows: removeItemsFromArray(state.minimizedWindows, action.names)
      })
    case CLOSE_WINDOW:
      return Object.assign({}, state, {
        openedWindows: removeFromArray(state.openedWindows, action.name),
        orderedWindows: removeFromArray(state.orderedWindows, action.name),
        maximzedWindows: removeFromArray(state.maximizedWindows, action.name),
        focusedWindow: otherOpenWindows.length > 0 ?
          otherOpenWindows[otherOpenWindows.length - 1] : ''
      })
    case MAXIMIZE_WINDOW:
      return Object.assign({}, state, {
        maximizedWindows: action.name === 'systemProperties' ? state.maximizedWindows : state.maximizedWindows.includes(action.name) ?
          removeFromArray(state.maximizedWindows, action.name) : state.maximizedWindows.concat(action.name),
        minimizedWindows: removeFromArray(state.minimizedWindows, action.name),
        orderedWindows: reorderWindows(state.orderedWindows, action.name),
        focusedWindow: action.name
      })
    case MINIMIZE_WINDOW:
      return Object.assign({}, state, {
        minimizedWindows: state.minimizedWindows.concat(action.name),
        focusedWindow: otherOpenWindows.length > 0 ?
          otherOpenWindows[otherOpenWindows.length - 1] : ''
      })
    case FOCUS_WINDOW:
      return Object.assign({}, state, {
        focusedWindow: action.name,
        minimizedWindows: removeFromArray(state.minimizedWindows, action.name),
        orderedWindows: reorderWindows(state.orderedWindows, action.name),
      })
    case TASKBAR_SELECT:
      if (state.minimizedWindows.includes(action.name)) {
        return Object.assign({}, state, {
          minimizedWindows: removeFromArray(state.minimizedWindows, action.name),
          focusedWindow: action.name,
          orderedWindows: reorderWindows(state.orderedWindows, action.name)
        });
      } else if (state.focusedWindow === action.name) {
        return Object.assign({}, state, {
          minimizedWindows: [...new Set([...state.minimizedWindows, action.name])],
          focusedWindow: otherOpenWindows.length > 0 ?
            otherOpenWindows[otherOpenWindows.length - 1] : ''
        })
      } else {
        return Object.assign({}, state, {
          focusedWindow: action.name,
          orderedWindows: reorderWindows(state.orderedWindows, action.name)
        });
      }
      case RESET_ALL_WINDOWS:
        return Object.assign({}, initialState)
      default:
        return state
  }
}