Array.prototype.pick = function() {
  return this[Math.floor(Math.random() * this.length)];
}

Array.prototype.addIfNotExists = function(item) {
  if (!this.includes(item)) {
    this.push(item);
  }
}

class MarkovChain {

  constructor(contentArray) {
    this.startWords = [];
    this.endWords = [];
    this.chain = {};
    this.totalItems = 0;
    this.averageLength = 0;

    contentArray.forEach(function(item) {
      let itemArray = item.split(" ");
      this.startWords.addIfNotExists(itemArray[0]);
      this.endWords.addIfNotExists(itemArray[itemArray.length - 1]);
      this.addItemsToChain(itemArray);
      this.totalItems += itemArray.length;
    }, this);

    this.sortChain();
    this.averageLength = Math.ceil(this.totalItems / contentArray.length) * 0.5;
  }

  addItemsToChain(array) {
    array.forEach(function(word, i) {
      if (i < array.length - 1) {
        let nextWord = array[i + 1];
        let toInsert = {};
        toInsert[nextWord] = 1;
        if (this.chain.hasOwnProperty(word)) {
          let words = this.chain[word].words;
          if (words.some(el => (el.word === nextWord))) {
            words.forEach(function(e) {
              if (e.word === nextWord) {
                e.count += 1;
              }
            });
          } else {
            words.push({
              word: nextWord,
              count: 1
            });
          }
          this.chain[word].total += 1;
        } else {
          this.chain[word] = {
            words: [{
              word: nextWord,
              count: 1
            }]
          };
          this.chain[word].total = 1;
        }
      }
    }, this);
  }

  sortChain() {
    for (let word in this.chain) {
      this.chain[word].words.sort(function(a, b) {
        return a.count - b.count;
      })
    }
  }

  build() {
    let word = this.startWords.pick();
    let line = [word];
    while (this.chain.hasOwnProperty(word)) {
      let link = this.chain[word];
      let runningTotal = 0;
      let r = Math.ceil(Math.random() * link.total);

      for (let candidate of link.words) {
        runningTotal += candidate.count;
        if (runningTotal >= r) {
          word = candidate.word;
          break;
        }
      }

      line.push(word);

      if (line.length > this.averageLength && this.endWords.includes(word)) {
        break;
      }
    }
    return line.join(" ");
  }
}

export default MarkovChain;