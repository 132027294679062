import React from 'react';
import './App.css';
import Window from './Window';
import WindowToolbar from './WindowToolbar';
import {
  maximizeWindow,
  minimizeWindow,
} from './actions'
import {
  connect
} from 'react-redux';

import minimize_icon from './icons/minimize_icon.png';
import maximize_icon from './icons/maximize_icon.png';

import notepad_icon from './icons/notepad-3.png';

const Notepad = ({
  title,
  name,
  children,
  minimizeWindow,
  maximizeWindow
}) => {

  const drag = (e) => {
    e.preventDefault();
  }
  return (
    <Window name={name} icon={notepad_icon} title={`${title} - Notepad`}
      windowClass="welcome-textpad-window"
      actions={[{'action':minimizeWindow,'icon':minimize_icon}, {'action':maximizeWindow,'icon':maximize_icon}]}>
      <WindowToolbar />
      <div className="text-area-border">
          <div draggable={true} className="text-area selectable" onDragStart={drag}>
            { children }
          </div>
        <div className="scrollbar-h">
          <div className="scroll-button border-only">
            <div className="chevron-left">
            </div>
          </div>
          <div className="fill">
          </div>
          <div className="scroll-button border-only">
            <div className="chevron-right">
            </div>
          </div>
          <img src={require("./icons/bottom_corner.png")} alt="" width="16px" height="16px" />
        </div>
      </div>
    </Window>
  );
}

const mapDispatchToProps = {
  maximizeWindow,
  minimizeWindow
}

export default connect(null, mapDispatchToProps)(Notepad)