import React from 'react';
import './App.css';

import {
  launchVirus,
  startVirus,
  addLinesToVirus,
  displayBsod,
  openWindow,
  resetVirus,
  resetAllWindows,
  maximizeWindow,
  minimizeWindow
} from './actions'
import {
  connect
} from 'react-redux';

import Window from './Window';
import Dropdown from './Dropdown';
import IconButton from './IconButton';

import minimize_icon from './icons/minimize_icon.png';
import maximize_icon from './icons/maximize_icon.png';

import dashed_square from './icons/dashed_square.png'
import greyed_out_files from './icons/greyed_out_files.png';
import clipboard from './icons/clipboard.png';
import expand from './icons/expand.png';
import pointing_to_file_hand from './icons/pointing_to_file_hand.png';
import two_windows from './icons/two_windows.png';
import capital_A from './icons/capital_A.png';

import dos_icon from './icons/ms_dos-1.png';

const virusLines = ['C:\\WINDOWS\\Desktop>funny-picture.exe',
  ' ',
  ' /     \\   HA HA HA',
  '| () () |   YOU JUST MADE A BIG MISTAKE',
  ' \\  ^  /',
  '  |||||     HASTA LA VISTA TO YOUR COMPUTER BABY',
  '  |||||        IT WONT BE BACK',
  '',
  '                    -virus by 1337H4XC0D3R86'
]

const lines_to_display = [
  'Analyzing...',
  'Extracting...',
  'Installing...',
  'Writing registry entries...',
  'Unpacking code...',
  'Finding personal documents...',
  'Uploading personal documents...',
  'Reticulating splines...',
  'Laughing at your fanfic...',
  'Judging your WinAMP playlists...',
  'Mining bitcoins, whatever they are...',
  'Inspecting system properties...',
  'Feeling sense of pity for you...',
  'Checking no one is using the phone...',
  'Dialling up...',
  'Downloading a Rob Zombie MP3...',
  'Waiting 16 hours...',
  'Playing a Rob Zombie MP3...',
  'It\'s not even the right Rob Zombie MP3...',
  'Deleting Rob Zombie MP3...',
  'Reading email contacts...',
  'Sending self to email contacts...',
  'Telling your grandmother that you don\'t love her...',
  'Starting smoking...',
  'Searching Yahoo! for Tony Hawk Pro Skater 2 cheats...',
  'Checking Excite.com e-mail...',
  'Logging in to neopets...',
  'Logging out of neopets...',
  'Logging back in to neopets...',
  'Searching C:\\ drive for secrets...',
  'Asking Jeeves for "UFO Secrets"...',
  'Asking Jeeves for "THONG"...',
  'Deleting browser history...',
  'Logging in to neopets again...',
  'Logging in to Habbo Hotel...',
  'Regretting everything...',
  'Deleting C:\\*...',
  '������',
  '�����������',
  '�������',
  '���',
    //TODO add a bunch more of these
]

class BSOD extends React.Component {

  constructor(props) {
    super(props)
    this.undoVirus = this.undoVirus.bind(this)
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  async componentDidUpdate(prevProps) {
    if (this.props.isVirusLaunched && !this.props.isVirusStarted) {
      let element = document.getElementById("virus-console-content");
      this.props.startVirus()
      this.props.addLinesToVirus(virusLines)
      this.props.openWindow('bsodconsole')
      for (const line of lines_to_display) {
        this.props.addLinesToVirus([line])
        element.scrollTop = element.scrollHeight;
        await this.sleep(this.getRandomArbitrary(100, 750))
      }
      this.props.displayBsod()
    }
  }

  undoVirus() {
    this.props.resetVirus()
    this.props.resetAllWindows()
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  render() {
    return (
      <div className={`${this.props.isBsodDisplayed ? "desktop fill" : ""}`}>
        <Window name="bsodconsole" title="MS-DOS Prompt" icon={dos_icon} windowClass="console-window" style={{maxWidth:'600px'}}  actions={[{'action':this.props.minimizeWindow,'icon':minimize_icon}, {'action':this.props.maximizeWindow,'icon':maximize_icon}]}>
          <div className="window-menu-items">
            <Dropdown elements={['Auto','Something','Else']}/>
            <div className="icon-holder">
              <IconButton icon={dashed_square} />
              <IconButton icon={greyed_out_files} />
              <IconButton icon={clipboard} />
            </div>
            <IconButton icon={expand} />
            <div className="icon-holder">
              <IconButton icon={pointing_to_file_hand} />
              <IconButton icon={two_windows} />
            </div>
            <IconButton icon={capital_A} />
          </div>
          <div id="virus-console-content" className="console-content margin non-interactable fill" style={{whiteSpace:'pre'}} >
            Microsoft(R) Windows 98 < br/>
            &emsp;&emsp;(C) Copyright Microsoft Corp 1981 - 1998.<br/><br/>
            {
              this.props.virusLines.map(function(line, i) {
                return (
                  <div key={i}>
                    {line}
                  </div>
                )
              })
            }
          </div>
        </Window>
        <div
          className = {`fullscreen bsod-wrapper center-items ${this.props.isBsodDisplayed ? "" : "hidden "}`}
          onKeyDown = { this.undoVirus }
          tabIndex = "0" >
        <div className="bsod">
          <p><span className="bsod-headline">Windows</span></p>
          <p>Oh no! Remember this? It's the Blue Screen of Death!! Your dad is gonna be FURIOUS. This has probably happened because:</p>
          <ul>
            <li>You downloaded something and broke your computer.</li>
            <li>You deleted system32 because someone on MSN told you to and you broke your computer.</li>
            <li>Your computer is already broken and it's a miracle it even worked to begin with.</li>
          </ul>
          <p>You've really done it this time, idiot. I hope you're better at running away from home than you are at using a computer.</p>
          <p className="lastLineOfBSOD">Press any key to continue</p>
        </div>
      </div>
    </div>
    )
  }
}

const mapDispatchToProps = {
  launchVirus,
  startVirus,
  addLinesToVirus,
  displayBsod,
  openWindow,
  resetVirus,
  resetAllWindows,
  maximizeWindow,
  minimizeWindow
}

function mapStateToProps(state) {
  return {
    isVirusLaunched: state.osActions.isVirusLaunched,
    isVirusStarted: state.osActions.isVirusStarted,
    isBsodDisplayed: state.osActions.isBsodDisplayed,
    virusLines: state.osActions.virusLines,
    openedWindows: state.windowActions.openedWindows
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BSOD)