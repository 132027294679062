import React from 'react';
import {
  useCallback,
  useState
} from 'react';
import {
  useDrop
} from 'react-dnd';
import './App.css';
import StartMenu from './StartMenu';
import DesktopIcon from './DesktopIcon';
import Taskbar from './Taskbar';
import BSOD from './BSOD';
import SystemProperties from './SystemProperties';
import Readme from './Readme';
import YoMommaConsole from './YoMommaConsole';
import YoMommaExplanation from './YoMommaExplanation';
import WindowHolder from './WindowHolder';
import CV from './CV';

import {
  closeStartMenu,
  openWindow,
  openWindows,
  closeWindow,
  maximizeWindow,
  minimizeWindow,
  launchVirus
} from './actions'
import {
  connect
} from 'react-redux';
import update from 'immutability-helper';

import recycle_bin_full from './icons/recycle_bin_full-3.png';
import computer_explorer from './icons/computer_explorer-2.png';
import my_documents from './icons/directory_open_file_mydocs-0.png';
import exe from './icons/executable_script-1.png';
import notepad from './icons/notepad_file-0.png';
import internet from './icons/html-3.png'

const App = (props) => {

  const icons = [{
      icon: recycle_bin_full,
      title: 'Recycle Bin'
    },
    {
      icon: computer_explorer,
      title: 'My Computer',
      func: openWindow,
      target: 'systemProperties'
    },
    {
      icon: my_documents,
      title: 'My Documents'
    },
    {
      icon: exe,
      title: 'funny-picture.exe',
      func: launchVirus,
    }, {
      icon: notepad,
      title: 'README',
      func: openWindow,
      target: 'readMe'
    }, {
      icon: notepad,
      title: 'Yo Momma!',
      func: openWindows,
      target: ['yoMommaExplanation', 'yoMommaConsole']
    }, {
      icon: notepad,
      title: 'CV',
      func: openWindow,
      target: 'CV'
    },
  ]

  const [windows, setWindows] = useState({
    bsodconsole: {
      top: 80,
      left: 80,
      component: <BSOD/>
    },
    readMe: {
      top: 100,
      left: 20,
      component: <Readme/>
    },
    yoMommaConsole: {
      top: 200,
      left: 100,
      component: <YoMommaConsole/>
    },
    yoMommaExplanation: {
      top: 20,
      left: 20,
      component: <YoMommaExplanation/>
    },
    systemProperties: {
      top: 100,
      left: 80,
      component: <SystemProperties/>
    },
    CV: {
      top: 300,
      left: 80,
      component: <CV/>
    }
  });

  const moveWindow = useCallback((id, left, top) => {
    setWindows(update(windows, {
      [id]: {
        $merge: {
          left,
          top
        },
      },
    }));
  }, [windows, setWindows]);

  const [, dropRef] = useDrop(() => ({
    accept: "window",
    drop(item, monitor) {
      const delta = monitor.getDifferenceFromInitialOffset();
      const left = Math.round(item.left + delta.x);
      const top = Math.round(item.top + delta.y);
      moveWindow(item.id, left, top);
      return undefined;
    },
  }), [moveWindow]);

  const [isOff, setOff] = useState(false);

  if (props.isShutDown && !isOff) {
    setTimeout(function() {
      setOff(true)
    }, 2500);
  }

  return (
    <div className="container">
        <div ref={dropRef}
             className="desktop fill"
             onClick={props.closeStartMenu}>

          { Object.keys(windows).map((key)=>{
               const { left, top, component } = windows[key];
               return (<WindowHolder key={key} id={key} left={left} top={top}>
                 {component}
               </WindowHolder>)
            })
          }

          <div className="desktop-icons">
            {
              icons && icons.map(function (icon, i) {
                    return (
                        <DesktopIcon
                            icon={icon.icon}
                            title={icon.title}
                            func={icon.func}
                            target={icon.target}
                            key={icon + i}/>
                    )
                  }
              )
            }

          </div>
          <StartMenu/>
        </div>
        <Taskbar/>
        {/*icons from https://win98icons.alexmeub.com/ */}
        {/*originally from https://www.nirsoft.net/utils/iconsext.html*/}
        {/*reference material : https://guidebookgallery.org/screenshots/win98*/}
        <div className={`fullscreen shutdown ${props.isShutDown ? "": "hidden"}`}>
        </div>
        <div className={`fullscreen off ${isOff ? "" : "hidden"}`}>
        </div>
      </div>
  );
}

/*
<div>
  <a href="http://cv.joerowe.co.uk" target="_blank"
     rel="noopener noreferrer"><DesktopIcon icon={internet}
                                            title="My CV!"
                                            key="mycv"/></a>
</div>

*/

const mapDispatchToProps = {
  closeStartMenu,
  openWindow,
  openWindows,
  closeWindow,
  launchVirus,
  maximizeWindow,
  minimizeWindow
}

function mapStateToProps(state) {
  return {
    isShutDown: state.startMenuActions.isShutDown
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)