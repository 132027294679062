import React from 'react';
import './App.css';

class IconButton extends React.Component {

  render() {
    return (
      <div className="interactable center-items icon-button">
        <img src={this.props.icon} width="14px" height="14x" alt={this.props.icon}/>
      </div>
    );
  }
}

export default IconButton;
