import React from 'react';
import './App.css';
import Notepad from './Notepad';

const CV = () => {

  return (
    <Notepad name="CV" title={"CV"}>
        <div>
          <p>CURRICULUM VITAE - JOE ROWE</p>
          <p>-------------------------------------</p>
          <p><u>Employment</u></p>
          <p>I'm an experienced software engineer and newly made technical lead. I'm passionate about serverless architecture, automation, and motivation. I love creating tools that help engineers deliver value more effectively and efficiently, and love to share good stuff with lunch and learns, lightning talks, brown bag sessions, and any other engineering word for a show and tell !</p>
          <p>Feb '21 - present - Technical Lead, MatchesFashion</p>
          <p>May '20 - Feb '21 - Senior Engineer, Tesco</p>
          <p>Jul '19 - May '20 - Engineer Enabler, Travelex</p>
          <p>Apr '17 - Jul '19 - Software Engineer, White Clarke Group</p>
          <p>Aug '16 - Apr '17 - Graduate Software Engineer, White Clarke Group </p>
          <p>Sep '14 - Aug '16 - Computer Science Teacher, The Watford UTC (don't wanna talk about it)</p>
          <br/>
          <p><u>Education</u></p>
          <p>2010 - 2014 - BSc Computer Science, First Class Honours - The University of Hertfordshire</p>
          <p>2013 - Year Abroad - 大分大学、九州、日本 </p>
          <p>2012 - CCNA 1, CCNA 2</p>
          <br />
          <p><u>Hobbies and Interests</u></p>
          <p>I enjoy learning new languages and frameworks, particularly front-end ones that I don’t get a chance to really dig in to during my day job (can you tell?). I also like playing with tools like Unity and Blender. I love working with people who share a passion for stuff like this.</p>
          <p>Outside of computing I enjoy boring old films, taking my dogs for long walks in the countryside, VR gaming, 3D modelling, and general aviation (now consigned to a home flight sim in VR).</p>
          <p>I am also continuing my Japanese language studies and am coming up to the fifth anniversary of me saying "I’m going to sit Level 2 of the Japanese Language Proficiency Test this year".</p>
        </div>
      </Notepad>
  );
}

export default CV