import React from 'react';
import ReactDOM from 'react-dom';
import {
  Provider
} from 'react-redux'
import {
  DndProvider
} from "react-dnd";
import {
  HTML5Backend
} from 'react-dnd-html5-backend'
import {
  TouchBackend
} from "react-dnd-touch-backend";
import MultiBackend, {
  TouchTransition,
  MouseTransition
} from "react-dnd-multi-backend";
import {
  createStore
} from 'redux'
import rootReducer from './reducers/index'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

const store = createStore(rootReducer)

const CustomHTML5toTouch = {
  backends: [{
      backend: HTML5Backend,
      transition: MouseTransition
      // by default, will dispatch a duplicate `mousedown` event when this backend is activated
    },
    {
      backend: TouchBackend,
      // Note that you can call your backends with options
      options: {
        enableMouseEvents: true
      },
      transition: TouchTransition,
      // will not dispatch a duplicate `touchstart` event when this backend is activated
      skipDispatchOnTransition: true
    }
  ]
};

ReactDOM.render(
  <React.StrictMode>
    <DndProvider backend={MultiBackend} options={CustomHTML5toTouch}>
        <Provider store={store}>
          <App />
        </Provider>
      </DndProvider>
    </React.StrictMode>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();