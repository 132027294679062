import notepad_icon from './icons/notepad-3.png';
import dos_icon from './icons/ms_dos-1.png';

const WINDOWS = {
  'systemProperties': {
    'name': 'System Properties'
  },
  'readMe': {
    'name': 'README - Notepad',
    'icon': notepad_icon
  },
  'yoMommaConsole': {
    'name': 'Yo Momma!',
    'icon': dos_icon
  },
  'yoMommaExplanation': {
    'name': 'Yo Momma - Explained!',
    'icon': notepad_icon
  },
  'CV': {
    'name': 'CV',
    'icon': notepad_icon
  },
  'bsodconsole': {
    'name': 'MSDOS',
    'icon': dos_icon
  }
}

export default WINDOWS