import React from 'react';
import './App.css';
import {
  connect
} from 'react-redux';


import Button from './Button';

class WindowActionButtons extends React.Component {

  constructor(props) {
    super(props)
    this.do = this.do.bind(this)
  }

  do(action, params) {
    if (action) {
      this.props.dispatch(action(params))
    }
  }

  render() {
    return (
      <div className="window-action-buttons">
      {
        this.props.actions && this.props.actions.map(function(action,i){
          return (
            <Button
              text={action.text}
              key={action.text+i}
              action={()=>{this.do(action.action, action.params)}} />
          )}.bind(this)
        )
      }
      </div>
    );
  }
}

export default connect()(WindowActionButtons)