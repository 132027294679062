/*
 * Actions
 */
export const FOCUS_WINDOW = "FOCUS_WINDOW"
export const TASKBAR_SELECT = "TASKBAR_SELECT"
export const OPEN_WINDOW = "OPEN_WINDOW"
export const OPEN_WINDOWS = "OPEN_WINDOWS"
export const CLOSE_WINDOW = "CLOSE_WINDOW"
export const MAXIMIZE_WINDOW = "MAXIMIZE_WINDOW"
export const MINIMIZE_WINDOW = "MINIMIZE_WINDOW"
export const RESET_ALL_WINDOWS = "RESET_ALL_WINDOWS"

export const CLOSE_START_MENU = "CLOSE_START_MENU"
export const TOGGLE_START_MENU = "TOGGLE_START_MENU"
export const SHUT_DOWN = "SHUT_DOWN"

export const LAUNCH_VIRUS = "LAUNCH_VIRUS"
export const START_VIRUS = "START_VIRUS"
export const ADD_LINES_TO_VIRUS = "ADD_LINES_TO_VIRUS"
export const DISPLAY_BSOD = "DISPLAY_BSOD"
export const RESET_VIRUS = "RESET_VIRUS"

/*
 * Action Creators
 */
export function focusWindow(name) {
  return {
    type: FOCUS_WINDOW,
    name
  }
}

export function taskbarSelect(name){
  return {
    type: TASKBAR_SELECT,
    name
  }
}

export function openWindow(name) {
  return {
    type: OPEN_WINDOW,
    name
  }
}

export function openWindows(names) {
  return {
    type: OPEN_WINDOWS,
    names
  }
}

export function closeWindow(name) {
  return {
    type: CLOSE_WINDOW,
    name
  }
}

export function maximizeWindow(name) {
  return {
    type: MAXIMIZE_WINDOW,
    name
  }
}

export function minimizeWindow(name) {
  return {
    type: MINIMIZE_WINDOW,
    name
  }
}

export function closeStartMenu() {
  return {
    type: CLOSE_START_MENU
  }
}

export function resetAllWindows() {
  return {
    type: RESET_ALL_WINDOWS
  }
}

export function toggleStartMenu() {
  return {
    type: TOGGLE_START_MENU
  }
}

export function shutDown() {
  return {
    type: SHUT_DOWN
  }
}

export function launchVirus() {
  return {
    type: LAUNCH_VIRUS
  }
}

export function startVirus() {
  return {
    type: START_VIRUS
  }
}

export function addLinesToVirus(lines) {
  return {
    type: ADD_LINES_TO_VIRUS,
    lines
  }
}

export function displayBsod() {
  return {
    type: DISPLAY_BSOD
  }
}

export function resetVirus() {
  return {
    type: RESET_VIRUS
  }
}